import { createTheme } from "@mui/material/styles";
import red from "@mui/material/colors/red";

export const theme = createTheme({
	palette: {
		primary: {
			main: "#E61E28"
		},
	},
});
