import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Logo from "../assets/logo";
const Navbar = () => {
	return (
		<AppBar position="fixed" className="navbar">
			<Toolbar>
				<Link to="/" style={{ textDecoration: "none" }}>
					<Typography style={{ color: "white" }} variant="h4">
						Data Hall Sizing Tool
					</Typography>
				</Link>
				<a
					style={{ marginLeft: "auto", marginRight: "-10px" }}
					href="https://arup.sharepoint.com"
					target="_blank"
					rel="noopener noreferrer"
					aria-label="link to Arup Sharepoint Homepage"
				>
					<Logo style={{ marginBottom: "-5px" }} height="30" />
				</a>
			</Toolbar>
		</AppBar>
	);
};

export default Navbar;
