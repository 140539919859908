import Typography from "@mui/material/Typography";
import { useMsal } from "@azure/msal-react";

interface IFormInput {
	CabAisleDepth: number;
	HotAisle: number;
	Cold: number;
	CabinetRackWidth: number;
	CoolingHallWidth: number;
	CirculationCorridor: number;
	MWModule: number;
	NumberOfHalls: number;
	TargetLoadDensity: number;
	SingleDouble: "single" | "double";
}

const Outputs = ({ values }: { values: IFormInput }) => {
	const { accounts } = useMsal();
	let AisleLength: number = 0;
	let OverallDataHallWidth: number = 0;
	let NumberOfCabsInAisle: number = 0;
	let ModuleWidth: number = 0;
	let ModuleArea: number = 0;
	let TargetMWHall: number = 0;
	let DataHallArea: number = 0;
	let DataHallLength: number = 0;
	let NumberOfCabAisles: number = 0;
	let ActualDataHallArea: number = 0;
	let ActualLoad: number = 0;
	let NumberOfCabs: number = 0;
	let AverageCabLoad: number = 0;
	ModuleWidth =
		values.CabAisleDepth + values.CabAisleDepth + values.HotAisle + values.Cold;
	if (values.SingleDouble === "single") {
		console.log(values);
		AisleLength = values.CoolingHallWidth - values.CirculationCorridor;
		console.log(values.CirculationCorridor);
		console.log(AisleLength);
		OverallDataHallWidth =
			AisleLength + values.CirculationCorridor + values.CirculationCorridor;
		console.log(OverallDataHallWidth);
		NumberOfCabsInAisle = Math.ceil(
			(AisleLength * 1000) / values.CabinetRackWidth
		);
		console.log(NumberOfCabAisles);
		ModuleArea = ModuleWidth * OverallDataHallWidth;
		console.log(ModuleArea);
	}

	if (values.SingleDouble === "double") {
		AisleLength =
			values.CoolingHallWidth -
			values.CirculationCorridor -
			values.CirculationCorridor;
		OverallDataHallWidth =
			AisleLength + values.CirculationCorridor + values.CirculationCorridor;
		NumberOfCabsInAisle = Math.ceil(
			(AisleLength * 1000) / values.CabinetRackWidth
		);
		ModuleArea = ModuleWidth * values.CoolingHallWidth;
	}

	TargetMWHall = values.MWModule / values.NumberOfHalls;
	DataHallArea = (TargetMWHall * 1000) / values.TargetLoadDensity;
	DataHallLength =
		(DataHallArea / ModuleArea) * ModuleWidth + values.CirculationCorridor * 2;
	NumberOfCabAisles =
		((DataHallLength -
			values.CirculationCorridor -
			values.CirculationCorridor) /
			ModuleWidth) *
		2;
	NumberOfCabAisles=Math.ceil(NumberOfCabAisles);
	ActualDataHallArea = Math.ceil(DataHallLength * OverallDataHallWidth);
	ActualLoad = (TargetMWHall * 1000) / ActualDataHallArea;
	ActualLoad = Math.round((ActualLoad + Number.EPSILON) * 100) / 100;
	NumberOfCabs = Math.ceil(NumberOfCabAisles * NumberOfCabsInAisle);
	AverageCabLoad = (TargetMWHall * 1000) / NumberOfCabs;

	const inputStyle = {
		marginTop: "2em",
		marginBottom: "2em"
	  };

	return (
		<div style={inputStyle}>
			<Typography variant="h2" sx={{ marginBottom: "20px", fontSize: "2em" }}>
				{" "}
				Outputs
			</Typography>

			<Typography variant="body1"> Number of Cabs in Aisle: {NumberOfCabsInAisle}</Typography>

			<Typography variant="body1">Module Width: {ModuleWidth.toLocaleString()} m </Typography>

			<Typography variant="body1">Module Area: {ModuleArea.toLocaleString()} m<sup>2</sup> </Typography>

			<Typography variant="body1">Target MW/hall: {TargetMWHall} MW</Typography>

			<Typography variant="body1">Data Hall Area: {DataHallArea} m<sup>2</sup> </Typography>

			<Typography variant="body1">Data Hall Width: {OverallDataHallWidth} m </Typography>

			<Typography variant="body1">Data Hall Length: {DataHallLength.toLocaleString()} m </Typography>

			<Typography variant="body1"># CAB Aisles: {NumberOfCabAisles.toLocaleString()}</Typography>

			<Typography variant="body1">Actual Data Hall Area: {ActualDataHallArea} m<sup>2</sup> </Typography>

			<Typography variant="body1">Actual Load: {ActualLoad} kW/m<sup>2</sup> </Typography>

			<Typography variant="body1">Number Of Cabs: {NumberOfCabs}</Typography>

			<Typography variant="body1">Average Cab Load: {AverageCabLoad.toLocaleString()} kW/Cab </Typography>
		</div>
	);
};

export default Outputs;
