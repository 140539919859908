import Typography from "@mui/material/Typography";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { useState } from "react";
import Button from "@mui/material/Button";
import Outputs from "../components/Outputs";
import { TextField } from "@material-ui/core";
import { MenuItem } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputAdornment from '@mui/material/InputAdornment';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { HelperIcon } from "../components/HelperIcon";

enum SingleDoubleEnum {
	single = "single",
	double = "double",
}

interface IFormInput {
	CabAisleDepth: number;
	HotAisle: number;

	Cold: number;
	CabinetRackWidth: number;
	CoolingHallWidth: number;
	CirculationCorridor: number;
	MWModule: number;
	NumberOfHalls: number;
	TargetLoadDensity: number;
	SingleDouble: "single" | "double";
}

const schema = yup
	.object()
	.shape({
		CabAisleDepth: yup.number().min(0, "Minimum value is 0").max(100, "Maximum value is 100").required(),
		HotAisle: yup.number().min(0, "Minimum value is 0").max(100, "Maximum value is 100").required(),
		Cold: yup.number().min(0, "Minimum value is 0").max(100, "Maximum value is 100").required(),
		CabinetRackWidth: yup.number().min(0, "Minimum value is 0").max(100, "Maximum value is 100").required(),
		CoolingHallWidth: yup.number().min(0, "Minimum value is 0").max(100, "Maximum value is 100").required(),
		CirculationCorridor: yup.number().min(0, "Minimum value is 0").max(100, "Maximum value is 100").required(),
		MWModule: yup.number().min(0, "Minimum value is 0").max(100, "Maximum value is 100").required(),
		NumberOfHalls: yup.number().min(0, "Minimum value is 0").max(100, "Maximum value is 100").required(),
		TargetLoadDensity: yup.number().min(0, "Minimum value is 0").max(100, "Maximum value is 100").required(),
		SingleDouble: yup.string().required(),
	})
	.required();

const UserInputs = () => {
	const [inputValues, setInputValues] = useState<IFormInput | undefined>();
	const { handleSubmit, getValues, control, formState: { errors } } = useForm<IFormInput>({
		defaultValues: {
			CabAisleDepth: 0,
			HotAisle: 0,
			Cold: 0,
			CabinetRackWidth: 0,
			CoolingHallWidth: 0,
			CirculationCorridor: 0,
			MWModule: 0,
			NumberOfHalls: 0,
			TargetLoadDensity: 0,
			SingleDouble: "single",
		},
		resolver: yupResolver(schema),
		mode: "onBlur",
	});
	const onSubmit: SubmitHandler<IFormInput> = (data) => setInputValues(data);


  const inputStyle = {
    marginTop: "1.5em",
    marginBottom: "1.5em",
	
  };

  
	return (
		<div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-around"}}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div>
					<Typography
						variant="h2"
						sx={{ marginBottom: "20px", fontSize: "1.5em" }}
					>
						Cooling Parameters:{" "}
					</Typography>
				</div>

				<div style={inputStyle}>
					<Controller
						name="CoolingHallWidth"
						control={control}
						render={({ field }) => (
							<TextField
								type="number"
								label="Cooling Hall Width"
								variant="outlined"
								helperText={errors.CoolingHallWidth && errors.CoolingHallWidth.message}
								error={!! errors.CoolingHallWidth}
                InputProps={{
                endAdornment: <InputAdornment position="end">m</InputAdornment>}}
								{...field}
							/>
						)}
					/>

					<HelperIcon message="Helper Text here"/>
				</div>

				<div style={inputStyle}>
					<Controller
						name="CirculationCorridor"
						control={control}
						render={({ field }) => (
							<TextField
								type="number"
								label="Circulation Corridor"
								variant="outlined"
								helperText={errors.CirculationCorridor && errors.CirculationCorridor.message}
								error={!! errors.CirculationCorridor}
                InputProps={{
                  endAdornment: <InputAdornment position="end">m</InputAdornment>}}
								{...field}
							/>
						)}
					/>
					<HelperIcon message="Helper Text here"/>
				</div>

				<Controller
					name="SingleDouble"
					control={control}
					render={({ field }) => (
						<TextField
							style={{ width: "10em" }}
							label="Single or Double"
							variant="outlined"
							select
							{...field}
						>
							<MenuItem value={"single"}>Single</MenuItem>
							<MenuItem value={"double"}>Double</MenuItem>
						</TextField>
					)}
				/>
				<HelperIcon message="Helper Text here"/>

<div style={inputStyle}>
					<Typography
						variant="h3"
						sx={{ marginBottom: "20px", fontSize: "1.5em" }}
					>
						Cab / Aisle Parameters:{" "}
					</Typography>
				</div>
				<div style={inputStyle}>
					<Controller
						name="CabAisleDepth"
						control={control}
						render={({ field }) => (
							<TextField
								type="number"
								label="Cab Aisle Depth"
								variant="outlined"
								helperText={errors.CabAisleDepth && errors.CabAisleDepth.message}
								error={!! errors.CabAisleDepth}
                InputProps={{
                  endAdornment: <InputAdornment position="end">m</InputAdornment>}}
								{...field}
							/>
						)}
					/>
					<HelperIcon message="Helper Text here"/>
				</div>

				<div style={inputStyle}>
					<Controller
						name="HotAisle"
						control={control}
						render={({ field }) => (
							<TextField
								type="number"
								label="Hot Aisle"
								variant="outlined"
								helperText={errors.HotAisle && errors.HotAisle.message}
								error={!! errors.HotAisle}
                InputProps={{
                  endAdornment: <InputAdornment position="end">m</InputAdornment>}}
								{...field}
							/>
						)}
					/>
					<HelperIcon message="Helper Text here"/>
				</div>

				<div style={inputStyle}>
					<Controller
						name="Cold"
						control={control}
						render={({ field }) => (
							<TextField
								type="number"
								label="Cold"
								variant="outlined"
								helperText={errors.Cold && errors.Cold.message}
								error={!! errors.Cold}
                InputProps={{
                  endAdornment: <InputAdornment position="end">m</InputAdornment>}}
								{...field}
							/>
						)}
					/>
					<HelperIcon message="Helper Text here"/>
				</div>

				<div>
					<Typography
						variant="h3"
						sx={{ marginBottom: "20px", fontSize: "1.5em" }}
					>
						Cabinet / Rack Width:{" "}
					</Typography>
				</div>

				<div style={inputStyle}>
					<Controller
						name="CabinetRackWidth"
						control={control}
						render={({ field }) => (
							<TextField
								type="number"
								label="Cabinet Rack Width"
								variant="outlined"
								helperText={errors.CabinetRackWidth && errors.CabinetRackWidth.message}
								error={!! errors.CabinetRackWidth}
                InputProps={{
                  endAdornment: <InputAdornment position="end">mm</InputAdornment>}}
								{...field}
							/>
						)}
					/>
					<HelperIcon message="Helper Text here"/>
				</div>

				<div style={inputStyle}>
					<Controller
						name="MWModule"
						control={control}
						render={({ field }) => (
							<TextField
								type="number"
								label="MW Module"
								variant="outlined"
								helperText={errors.MWModule && errors.MWModule.message}
								error={!! errors.MWModule}
                InputProps={{
                  endAdornment: <InputAdornment position="end">MW</InputAdornment>}}
								{...field}
							/>
						)}
					/>
					<HelperIcon message="Helper Text here"/>
				</div>

				<div style={inputStyle}>
					<Controller
						name="NumberOfHalls"
						control={control}
						render={({ field }) => (
							<TextField
								type="number"
								label="# of Halls"
								variant="outlined"
								helperText={errors.NumberOfHalls && errors.NumberOfHalls.message}
								error={!! errors.NumberOfHalls}
								{...field}
							/>
						)}
					/>
					<HelperIcon message="Helper Text here"/>
				</div>

				<div style={inputStyle}>
					<Controller
						name="TargetLoadDensity"
						control={control}
						render={({ field }) => (
							<TextField
								type="number"
								label="Target Load Density"
								variant="outlined"
								helperText={errors.TargetLoadDensity && errors.TargetLoadDensity.message}
								error={!! errors.TargetLoadDensity}
                InputProps={{
                  endAdornment: <InputAdornment position="end">kW/m <sup>2</sup> </InputAdornment>}}
								{...field}
							/>
						)}
					/>
					<HelperIcon message="Helper Text here"/>
				</div>

				<Button size="small" variant="contained" type="submit">
					Calculate
				</Button>
			</form>

			<div>{inputValues && <Outputs values={inputValues} />}</div>
		</div>
	);
};

export default UserInputs;
