import {
	InteractionRequiredAuthError,
	RedirectRequest,
	SilentRequest,
} from "@azure/msal-browser";
import msalInstance, { loginRequest } from "./authConfig";

const tokenRequest = async (request: SilentRequest | RedirectRequest) => {
	let token;
	try {
		const account = await msalInstance.getActiveAccount();
		if (!account) {
			throw Error(
				"No active account! Verify a user has been signed in and setActiveAccount has been called."
			);
		}
		token = await msalInstance.acquireTokenSilent({ ...request, account });
	} catch (error) {
		try {
			// fallback to interaction when silent call fails
			if (error instanceof InteractionRequiredAuthError) {
				token = await msalInstance.acquireTokenRedirect(request);
			} else {
				// eslint-disable-next-line no-console
				console.error(error);
			}
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(err);
		}
	}
	return token;
};

export const getGraphToken = async () => {
	/**
	 * TODO accept scopes requested from MG Toolkit components
	 */
	const token = await tokenRequest(loginRequest);
	return token?.accessToken ?? "";
};

export const logout = async () => {
	await msalInstance.logoutRedirect();
};
export const login = async () => {
	await msalInstance.loginRedirect();
};
