import Navbar from "./components/Navbar";
import { Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import Container from "@mui/material/Container";
import { theme } from "./style/theme";
import { ThemeProvider } from "@mui/material/styles";

// auth imports
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./auth/authConfig";

// pages
import Home from "./pages/Home";
import UserInputs from "./pages/UserInputs";
import Outputs from "./components/Outputs";

const queryClient = new QueryClient();

function App() {
	return (
		<MsalAuthenticationTemplate
			interactionType={InteractionType.Redirect}
			authenticationRequest={loginRequest}
		>
			<QueryClientProvider client={queryClient}>
				<ThemeProvider theme={theme}>
					<Container
						className="main-content"
						sx={{ maxWidth: "1400px" }}
						maxWidth={false}
					>
						<Navbar />
						<Routes>
							<Route path="/" element={<UserInputs />} />
							{/* <Route path="/Outputs" element={<Outputs />}/> */}
						</Routes>
					</Container>
				</ThemeProvider>
				<ReactQueryDevtools initialIsOpen={false} />
			</QueryClientProvider>
		</MsalAuthenticationTemplate>
	);
}

export default App;
